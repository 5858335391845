import React from 'react';

import { Header, Segment, Icon, Placeholder } from 'semantic-ui-react';

export default function Facturation (props) {

    return (<>
        <Header attached='top' size='huge'>
            <Icon name='file alternate' />
            Facturation et pénalités
        </Header>

        <Segment attached padded> 

        <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
        </Placeholder>

        <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
        </Placeholder>

        <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
        </Placeholder>

        </Segment>
    </>)
}