import React from 'react';

import { Form, Header, Segment, Icon } from 'semantic-ui-react';

import { useAuth } from '../Auth.context';
import { UserRoles } from '../Enums';

export default function DonneesEntreprise (props) {

    const { user } = useAuth();

    return (<>

        <Header attached='top' size='huge'>
            <Icon name='id card' />
            Données d'entreprise
        </Header>

        <Segment attached padded>

            <Form fluid>

                <Header>Informations de compte</Header>

                <Form.Group widths='equal'>

                    
                    <Form.Input
                    type='email'
                    label='Adresse email : '
                    placeholder='example@email.com'

                     />

                    <Form.Input
                    type='password'
                    label='Mot de passe : '
                    placeholder='***********'

                    />

                    <Form.Input
                    type='password'
                    label='Confirmez le mot de passe : '
                    placeholder='***********'

                    />

                </Form.Group>

                <Form.Button
                icon='lock'
                labelPosition='left'
                content='Modifier les informations'
                />

                <Header>Informations de l'entreprise</Header>

                <Form.Group widths='equal'>

                    <Form.Input
                    type='text'
                    label='Dénomination sociale'
                    placeholder='Dénomination sociale'
                     />

                    <Form.Input
                    type='text'
                    label='Nom du dirigeant'
                    placeholder='Nom du dirigeant'
                    />

                    <Form.Input
                    type='text'
                    label='Prénom du dirigeant'
                    placeholder='Prénom du dirigeant'
                    />

                </Form.Group>

                <Form.Group widths='16'>

                    <Form.Input
                    type='text'
                    label='Adresse du siège social'
                    placeholder='Adresse du siège social'
                    width={10}
                    />

                    <Form.Input
                    type='number'
                    label='Code postal'
                    placeholder='76600'
                    width={2}
                    />

                    <Form.Input
                    type='text'
                    label='Ville'
                    placeholder='Le Havre'
                    width={4}
                    />

                </Form.Group>

                <Form.Group widths='16'>

                    <Form.Input
                    type='text'
                    label='Numéro de SIRET'
                    placeholder='Numéro de SIRET'
                    width={5}
                    />

                    <Form.Input
                    type='text'
                    label='Numéro de TVA'
                    placeholder='Numéro de TVA'
                    width={5}
                    />

                    <Form.Input
                    type='text'
                    label='Licence communautaire'
                    placeholder='Licence communautaire'
                    width={3}
                    />

                    <Form.Input
                    type='text'
                    label='Code NAF'
                    placeholder='Code NAF'
                    width={3}
                    />

                </Form.Group>

                {user.accessLevel === UserRoles.TRANSPORTEUR_ADMIN &&
                    <Form.Button
                    icon='lock'
                    labelPosition='left'
                    content='Modifier les informations'
                    />
                }

            </Form>

        </Segment>
    </>)
}