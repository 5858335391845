import React, { useState, useEffect, createRef } from 'react';

import { Header, Segment, Icon, Input, Form, Table, Button, Message, Confirm } from 'semantic-ui-react';

//import containerValidator from 'container-validator';

export default function PageInspection (props) {

    const inputRef = createRef();

    const [noCtr, setNoCtr] = useState('');
    const [noRdv, setNoRdv] = useState('');
    const [noLot, setNoLot] = useState('');

    const [rdvs, setRdvs] = useState([]);

    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [error, setError] = useState();

    const handleCtrType = (e, { value }) => {

    }

    const handleRdvType = (e, { value }) => {
        // TODO pouvoir taper que les derniers numeros du numero de rdv pour le proposer
    }

    const handleLotType = (e, { value }) => {
        // TODO ajoute automatiquement le premier CTR le plus accessible à la liste à confirmer
    }

    const handleSubmit = (e) => {
        
    }

    useEffect(() => { 
        window.scrollTo(0, 0); 
        inputRef.current.focus();
    });

    return (<>
    <Header 
    size='huge'
    inverted>
        <Icon name='tasks' />
        <Header.Content>
            Inspection
            <Header.Subheader>
                Inspection des rendez-vous prévus à TNMSC
            </Header.Subheader>
        </Header.Content>
    </Header>

    <Segment>

        <Table
        celled
        striped
        selectable>

            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell>

                        <Form fluid loading={loading}>
                            <Form.Group widths='equal'>

                                <Form.Field>
                                    <label>Numéro de rendez-vous: </label>
                                    <Input
                                    type='text'
                                    name='rdv'
                                    action={{ icon: 'search' }}
                                    placeholder='Numéro de rendez-vous'
                                    ref={inputRef}
                                    value={noRdv}
                                    onChange={handleRdvType}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Numéro de conteneur: </label>
                                    <Input
                                    type='text'
                                    name='ctr'
                                    action={{ icon: 'search' }}
                                    placeholder='Numéro de conteneur'
                                    value={noCtr}
                                    onChange={handleCtrType}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Numéro de lot: </label>
                                    <Input
                                    type='text'
                                    name='lot'
                                    action={{ icon: 'search' }}
                                    placeholder='Numéro de lot'
                                    value={noLot}
                                    onChange={handleLotType}
                                    />
                                </Form.Field>

                            </Form.Group>

                            <Form.Field>

                                {error && 
                                    <Message
                                    fluid
                                    negative
                                    icon>
                                        <Icon name='exclamation circle' />
                                        <Message.Content>
                                            <Message.Header>Erreur</Message.Header>
                                            Rendez-vous hors créneau
                                        </Message.Content>
                                    </Message>
                                }

                                <Header>Liste des rendez-vous à confirmer : </Header>
                            </Form.Field>
                            
                        </Form>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>

                <Table.Row>
                    <Table.Cell></Table.Cell>
                </Table.Row>

            </Table.Body>

            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell >
                        <Button
                        floated='right'
                        positive
                        
                        icon
                        labelPosition='left'
                        onClick={(e) => { setShowConfirm(true); }}
                        >
                            <Icon name='check' />
                            Confirmer le(s) rendez-vous
                        </Button>
                        <Confirm
                        open={showConfirm}
                        content='Êtes vous sur de vouloir valider ces rendez-vous ?'
                        confirmButton='Oui, confirmer les RDV'
                        cancelButton='Non, revenir en arriere'
                        onCancel={(e) => { setShowConfirm(false); }}
                        onConfirm={handleSubmit}
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>

        </Table>
    </Segment>
    </>)
}