import React from 'react';

import { Header, Segment, Icon, Form, Message} from 'semantic-ui-react';

export default function PageInscription (props) {

    return (<div className='centerDiv'>
        <Header 
        inverted 
        icon
        size='huge' >
            <Icon name='user plus' circular />
            <Header.Content>
                Inscription
                <Header.Subheader>Page d'inscription au portail web de prise de rendez-vous à TNMSC</Header.Subheader>
            </Header.Content>
        </Header>

        <div className='inscription'>

            <Header 
            attached='top' 
            textAlign='center' 
            block
            size='large'>
                <Icon name='building' />
                Informations de l'entreprise
            </Header>

            <Segment attached >

                <Form>

                    <Form.Group widths='16'>
                        <Form.Input
                        width={6}
                        type='text'
                        label='Raison sociale :'
                        placeholder='Raison sociale'
                        />

                        <Form.Input
                        width={6}
                        type='text'
                        label='Numéro de SIRET :'
                        placeholder='Numéro de SIRET'
                        />

                        <Form.Input
                        width={4}
                        type='text'
                        label='Code APE/NAF :'
                        placeholder='Code APE/NAF'
                        />

                    </Form.Group>

                    <Form.Group widths={16}>
                        <Form.Input
                        width={9}
                        type='text'
                        label="Adresse de l'entreprise"
                        placeholder='Adresse'
                        />

                        <Form.Input
                        width={3}
                        type='number'
                        label='Code postal :'
                        placeholder='76XXX'
                        />

                        <Form.Input
                        width={4}
                        type='text'
                        label='Ville :'
                        placeholder='Ville'
                        />

                    </Form.Group>

                    <Form.Group widths={'equal'}>
                        <Form.Input
                        type='phone'
                        label="Numéro de téléphone"
                        placeholder='06XXXXXXXX'
                        />

                        <Form.Input
                        type='text'
                        label='Nom du dirigeant :'
                        placeholder='Nom du dirigeant'
                        />

                        <Form.Input
                        type='text'
                        label='Prénom du dirigeant :'
                        placeholder='Prénom du dirigeant'
                        />

                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Input
                        type='text'
                        label="Numéro de TVA intracommunautaire"
                        placeholder='Numéro de TVA'
                        />

                        <Form.Input
                        type='text'
                        label='Numéro de licence communautaire :'
                        placeholder='Numéro de licence'
                        />

                    </Form.Group>
                
                </Form>

            </Segment>
            
            <Header 
            attached 
            textAlign='center' 
            block
            size='large'>
                <Icon name='user' />
                Informations de compte
            </Header>

            <Segment attached>

                <Form>

                    <Form.Group widths='equal'>

                        <Form.Input
                        type='email'
                        label='Adresse E-mail'
                        placeholder='adresse@example.com'
                        />

                        <Form.Input
                        type='password'
                        label='Mot de passe : '
                        placeholder='********'
                        />

                        <Form.Input
                        type='password'
                        label='Confirmez le mot de passe :'
                        placeholder='********'
                        />

                    </Form.Group>

                    <Form.Field>
                        <Message icon>
                            <Icon name='info circle' />
                            <Message.Content>
                                <Message.Header>Informations importantes</Message.Header>
                                Une fois votre inscription envoyée, votre compte sera créé mais pas encore activé.
                                Vous receverez un mail une fois qu'il aura été validé et activé par nos services.
                            </Message.Content>
                        </Message>
                    </Form.Field>

                    <Form.Button
                    fluid
                    positive
                    icon='sign-in'
                    labelPosition='left'
                    size='big'
                    content='Inscription'
                    />

                </Form>

            </Segment>

        </div>
    </div>)
}