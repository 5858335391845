const StatutsRDV = {
    RDV_PRIS: {
        code: 0,
        text: 'RDV pris',
        label: <label style={{ color: '' }}>RDV pris</label>
    },
    RDV_CONFIRME: {
        code: 1,
        text: 'RDV confirmé',
        label: <label style={{ color: '' }}>RDV confirmé</label>
    },
    RDV_ENCOURS: {
        code: 2,
        text: 'RDV en cours',
        label: <label style={{ color: '' }}>RDV en cours</label>
    },
    RDV_EFFECTUE: {
        code: 3,
        text: 'RDV effectué',
        label: <label style={{ color: 'green' }}>RDV effectué</label>
    },
    RDV_NON_HONORE: {
        code: 4,
        text: 'RDV non honoré',
        label: <label style={{ color: 'red' }}>RDV non honoré</label>
    },
    RDV_BLOQUE: {
        code: 5,
        text: 'RDV bloqué',
        label: <label style={{ color: 'orange' }}>RDV bloqué</label>
    },
    RDV_ANNULE: {
        code: 6,
        text: 'RDV annulé',
        label: <label style={{ color: '' }}>RDV annulé</label>
    }
}

const allRDVStatuts = () => {
    return Object.values(StatutsRDV);
}

const getAllRDVStatutsRibbons = () => {
    let ribbons = []

    allRDVStatuts().map((statut) => {
        ribbons.push({ key: statut.code, value: statut.code, text: statut.label })
    });

    return ribbons
}

const UserRoles = {
    TRANSPORTEUR : 0,
    TRANSPORTEUR_ADMIN : 1,
    DOCKER_COMMIS : 2,
    DOCKER_HELPDESK : 3,
    DOCKER_CHEF_PARC : 4,
    DOCKER_RESPONSABLE : 5,
    ADMIN : 6
}

const allUserRoles = () => {
    return Object.values(UserRoles);
}

// return all UserRoles except denied roles
const allUserRolesExcept = (deniedRoles = []) => {
    let userRoles = []

    deniedRoles.map((deniedRole) => {
        Object.values(UserRoles).map((role) => {
            if(deniedRole != role){
                userRoles.push(role);
            }
        })
    })

    return userRoles;
}

const rolesSuperiorOrEqualTo = (userRole) => {
    let userRoles = []

    Object.values(UserRoles).map((role) => {
        if(role >= userRole){
            userRoles.push(role);
        }
    })
    
    return userRoles;
}

const onlyTransporteurs = () => {
    return [UserRoles.TRANSPORTEUR, UserRoles.TRANSPORTEUR_ADMIN];
}

export { 
    UserRoles, 
    allUserRolesExcept, 
    allUserRoles, 
    onlyTransporteurs, 
    rolesSuperiorOrEqualTo,

    StatutsRDV,
    allRDVStatuts,
    getAllRDVStatutsRibbons,
};