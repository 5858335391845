import React, { forwardRef, useState } from 'react';

import { Table, Input, Icon } from 'semantic-ui-react';

import { formatDate } from '../Utils';
import { UserRoles } from '../Enums';

import ReactDatePicker from 'react-datepicker';

export default function EscaleComponent ({ escale, user }) { // { vos, navire, eta, statut, ouvertureDry, ouvertureReefer, fermeture }

    const [ouvertureDry, setOuvertureDry] = useState(escale.ouvertureDry);
    const [ouvertureReefer, setOuvertureReefer] = useState(escale.ouvertureReefer);
    const [fermeture, setFermeture] = useState(escale.fermeture)

    const CustomInput = forwardRef(({ value, onClick }, ref) => {
        return <Input 
        icon 
        iconPosition='left'
        style={{ maxWidth: '195px' }}>
            <Icon name='sliders' />
            <input
            type='text'
            value={value}
            ref={ref}
            onClick={onClick}
            />
        </Input>
    })

    return (<Table.Row key={escale.vos}>
        <Table.Cell>{escale.vos}</Table.Cell>
        <Table.Cell>{escale.navire}</Table.Cell>
        <Table.Cell>{formatDate(escale.eta)}</Table.Cell>
        <Table.Cell>{escale.statut}</Table.Cell>

        <Table.Cell>
            {user.accessLevel < UserRoles.DOCKER_CHEF_PARC ? 
                formatDate(escale.ouvertureDry)
            :
                <ReactDatePicker
                selected={ouvertureDry}
                showTimeSelect
                timeFormat='HH:mm'
                timeIntervals={60}
                timeCaption='Créneau: '
                dateFormat='dd/MM/yyyy HH:mm'
                onChange={(date) => setOuvertureDry(date)}
                customInput={<CustomInput />}
                />
            }
        </Table.Cell>
        <Table.Cell>
            {user.accessLevel < UserRoles.DOCKER_CHEF_PARC ? 
                formatDate(escale.ouvertureReefer)
            :
                <ReactDatePicker
                selected={ouvertureReefer}
                showTimeSelect
                timeFormat='HH:mm'
                timeIntervals={60}
                timeCaption='Créneau: '
                dateFormat='dd/MM/yyyy HH:mm'
                onChange={(date) => setOuvertureReefer(date)}
                customInput={<CustomInput />}
                />
            }   
        </Table.Cell>
        <Table.Cell>
            {user.accessLevel < UserRoles.DOCKER_CHEF_PARC ? 
                formatDate(escale.fermeture)
            :
                <ReactDatePicker
                selected={fermeture}
                showTimeSelect
                timeFormat='HH:mm'
                timeIntervals={60}
                timeCaption='Créneau: '
                dateFormat='dd/MM/yyyy HH:mm'
                onChange={(date) => setFermeture(date)}
                customInput={<CustomInput />}
                />
            }
        </Table.Cell>
    </Table.Row>)
}