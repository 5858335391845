import React from "react";

import { Header, Segment, Icon, Grid } from 'semantic-ui-react';

export default function PageStatistiques (props) {

    return (<>
    <Header 
    size='huge' 
    inverted>
        <Icon name='chart pie' />
        <Header.Content>
            Statistiques
            <Header.Subheader>
                Page de statistiques des rendez-vous pris à TNMSC
            </Header.Subheader>
        </Header.Content>
    </Header>

    <Segment>

        <Grid columns={3} divided textAlign='center'>
            <Grid.Row>
                <Grid.Column width={6}>

                    <Header size='medium'>Stats de la journée</Header>

                </Grid.Column>

                <Grid.Column width={5}>

                    <Header size='medium'>Stats de la semaine</Header>
                    
                </Grid.Column>

                <Grid.Column width={5}>

                    <Header size='medium'>Stats du mois</Header>
                    
                </Grid.Column>
            </Grid.Row>
        </Grid>

    </Segment>
    </>)
}