import React, { useState } from "react";

import { Header, Segment, Icon, Menu, Grid } from 'semantic-ui-react';

import DonneesEntreprise from "../../components/donneesEntreprise.component";
import Facturation from '../../components/facturation.component';
import GestionUtilisateurs from '../../components/gestionUtilisateurs.component';

import { useAuth } from "../../Auth.context";

export default function PageEspaceClient (props) {

    const { user } = useAuth();

    const [activeTab, setActiveTab] = useState(0);

    return (<>
        <Header 
        size='huge' 
        inverted>
            <Icon name='user circle' />
            <Header.Content>
                Espace Client
                <Header.Subheader>
                    Accès à vos données d'entreprise, facturation, etc
                </Header.Subheader>
            </Header.Content>
        </Header>

        <Segment>
            
            <Grid>
                <Grid.Column width={2}>

                    <Menu vertical icon='labeled'>

                        <Menu.Item
                        active={activeTab === 0}
                        onClick={(e) => { setActiveTab(0) }} >
                            <Icon name='id card' />
                            Entreprise
                        </Menu.Item>

                        <Menu.Item
                        active={activeTab === 1}
                        onClick={(e) => { setActiveTab(1) }} >
                            <Icon name='file alternate' />
                            Facturation
                        </Menu.Item>

                        <Menu.Item
                        active={activeTab === 2}
                        onClick={(e) => { setActiveTab(2) }} >
                            <Icon name='users' />
                            Utilisateurs
                        </Menu.Item>
                    </Menu>
                </Grid.Column>

                <Grid.Column stretched width={14}>
                        
                    {activeTab === 0 && <DonneesEntreprise user={user} />}
                    {activeTab === 1 && <Facturation user={user} />}
                    {activeTab === 2 && <GestionUtilisateurs user={user} /> }

                </Grid.Column>
            </Grid>

        </Segment>
    </>)
}