import React, { useState, useEffect, forwardRef } from "react";
import { useLocation } from "react-router-dom";

import { Header, Segment, Icon, Grid, Table, Button, Form, Checkbox } from "semantic-ui-react";

import ReactDatePicker from "react-datepicker";

import { getAllRDVStatutsRibbons } from "../../Enums";
import { formatDate } from "../../Utils";

import { fakeRdvEntree, fakeRdvSortie } from "../../FakeData";

export default function PageInterrogation (props) {

    //TODO sortable

    const location = useLocation();

    const [rdvs, setRdvs] = useState([]);

    const [isCreneau, setIsCreneau] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [taille, setTaille] = useState('all');
    const [etat, setEtat] = useState('all');
    const [type, setType] = useState('all');
    const [transporteur, setTransporteur] = useState('all');
    const [statuts, setStatuts] = useState([]);
    const [sens, setSens] = useState('all');

    const [loading, setLoading] = useState(false);

    const CustomInput = forwardRef(({ value, onClick }, ref) => {
        return <Form.Input 
        icon 
        iconPosition='left'
        style={{ maxWidth: '195px' }}>
            <Icon name='calendar' />
            <input
            type='text'
            value={value}
            ref={ref}
            onClick={onClick}
            />
        </Form.Input>
    })

    const handleChangeOfDateType = (e, { checked }) => {
        setIsCreneau(checked)
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        if(location.state){
            setStartDate(location.state.date);
        }
    })

    return (<>
    <Header 
    size='huge' 
    inverted>
        <Icon name='list ol' />
        <Header.Content>
            Interrogation
            <Header.Subheader>
                Page d'interrogation des rendez-vous sur dates sélectionnées ou créneau
            </Header.Subheader>
        </Header.Content>
    </Header>

    <Segment>

        <Segment attached='top'>

            <Form>
                <Grid divided columns={2}>

                    <Grid.Column width={6}>

                        <Header size='tiny'>
                            Dates ou créneau : 
                            <Checkbox
                            label='Créneau ?'
                            toggle
                            checked={isCreneau}
                            onChange={handleChangeOfDateType}
                            style={{ float: 'right' }}
                            />
                        </Header>

                        <Form.Group widths='equal'>

                            <Form.Field>

                                <ReactDatePicker
                                selected={startDate}
                                showTimeSelect
                                timeFormat='HH:mm'
                                timeIntervals={60}
                                timeCaption='Créneau: '
                                dateFormat='dd/MM/yyyy HH:mm'
                                onChange={(date) => setStartDate(date)}
                                customInput={<CustomInput />}
                                />

                            </Form.Field>

                            <Form.Field>

                                {!isCreneau &&

                                    <ReactDatePicker
                                    selected={endDate}
                                    disabled={isCreneau}
                                    showTimeSelect
                                    timeFormat='HH:mm'
                                    timeIntervals={60}
                                    timeCaption='Créneau: '
                                    dateFormat='dd/MM/yyyy HH:mm'
                                    onChange={(date) => setEndDate(date)}
                                    customInput={<CustomInput />}
                                    />

                                }
                            
                            </Form.Field>

                        </Form.Group>

                        <Form.Field>

                            <Button
                            fluid
                            positive
                            icon='search'
                            labelPosition='left'
                            content='Rechercher'
                            />
                        
                        </Form.Field>

                    </Grid.Column>

                    <Grid.Column width={10}>

                        <Header size='tiny'>Filtres de recherche : </Header>

                        <Form.Group widths='equal'>

                            <Form.Select
                            compact
                            placeholder="Toutes tailles"
                            options={[
                                { key: 'all', value: 'all', text: 'Toutes tailles'},
                                { key: '20', value: '20', text: '20 pieds'},
                                { key: '40', value: '40', text: '40 pieds'},
                                { key: '45', value: '45', text: '45 pieds'},
                            ]}
                            defaultValue={'all'}
                            value={taille}
                            onChange={(e, { value}) => { setTaille(value); }}
                            />

                            <Form.Select
                            compact
                            placeholder="Vides et pleins"
                            options={[
                                { key: 'all', value: 'all', text: 'Vides et pleins'},
                                { key: 'vi', value: 'vi', text: 'Vides'},
                                { key: 'pl', value: 'pl', text: 'Pleins'},
                            ]}
                            defaultValue={'all'}
                            value={etat}
                            onChange={(e, { value}) => { setEtat(value); }}
                            />

                            <Form.Select
                            compact
                            placeholder="Tout types"
                            options={[
                                { key: 'all', value: 'all', text: 'Tout types'},
                                { key: 'dry', value: 'dry', text: 'Dry'},
                                { key: 'rf', value: 'rf', text: 'Reefers'},
                                { key: 'ot', value: 'ot', text: 'Open Tops'},
                                { key: 'fl', value: 'fl', text: 'Flats'},
                            ]}
                            defaultValue={'all'}
                            value={type}
                            onChange={(e, { value}) => { setType(value); }}
                            />

                            <Form.Select
                            compact
                            placeholder="Transporteurs"
                            options={[
                                { key: 'all', value: 'all', text: 'Transporteurs'},
                            ]}
                            defaultValue={'all'}
                            value={transporteur}
                            onChange={(e, { value}) => { setTransporteur(value); }}
                            />

                        </Form.Group>

                        <Form.Group widths='16'>

                            <Form.Select
                            width='4'
                            compact
                            placeholder="Sens du flux"
                            options={[
                                { key: 'all', value: 'all', text: 'Sens du flux'},
                                { key: 'entree', value: 'entree', text: 'Entrées'},
                                { key: 'entree', value: 'entree', text: 'Sorties'},
                            ]}
                            defaultValue={'all'}
                            value={sens}
                            onChange={(e, { value}) => { setSens(value); }}
                            />

                            <Form.Select
                            width='12'
                            multiple
                            clearable
                            placeholder='Statuts de rendez-vous'
                            options={getAllRDVStatutsRibbons()}
                            defaultValue='all'
                            value={statuts}
                            onChange={(e, { value }) => setStatuts(value) }
                            />

                        </Form.Group>
                        
                    </Grid.Column>

                </Grid>
            </Form>
        </Segment>

        {isCreneau &&

        <Segment attached='bottom'>

            <Header size='tiny'>Ajout d'un rendez-vous sur le créneau</Header>

            <Form>
                <Form.Group widths='equal'>

                    <Form.Input
                    type='text'
                    placeholder='Numéro de conteneur' />

                    <Form.Input
                    type='text'
                    placeholder='Numéro de référence' />

                    <Form.Input
                    type='text'
                    placeholder='Numéro de lot' />

                    <Form.Select
                    placeholder='Code transporteur'
                    options={[

                    ]} />

                    <Form.Field>

                        <Button
                        icon='calendar plus'
                        labelPosition='left'
                        positive
                        disabled
                        content='Ajouter le RDV' />
                    
                    </Form.Field>

                </Form.Group>
            </Form>
        </Segment>

        }

        <Table
        celled
        selectable
        striped
        sortable
        textAlign='center' >
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Créneau</Table.HeaderCell>
                    <Table.HeaderCell>Numéro de RDV</Table.HeaderCell>
                    <Table.HeaderCell>Statut</Table.HeaderCell>
                    <Table.HeaderCell>Sens</Table.HeaderCell>
                    <Table.HeaderCell>Transporteur</Table.HeaderCell>
                    <Table.HeaderCell>Numéro de CTR</Table.HeaderCell>
                    <Table.HeaderCell>ISO</Table.HeaderCell>
                    <Table.HeaderCell>État</Table.HeaderCell>
                    <Table.HeaderCell>Numéro de réf</Table.HeaderCell>
                    <Table.HeaderCell>Navire</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>

                <Table.Row>
                    <Table.Cell>{formatDate(fakeRdvEntree.date)}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.no}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.statut}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.sens}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.transporteur}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.ctr}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.iso}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.etat}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.ref}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.navire}</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>{formatDate(fakeRdvSortie.date)}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.no}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.statut}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.sens}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.transporteur}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.ctr}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.iso}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.etat}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.ref}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.navire}</Table.Cell>
                </Table.Row>
                
            </Table.Body>

            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell colSpan={10}>
                        <Button
                        floated='right'
                        icon='download'
                        labelPosition='left'
                        content='Exporter la liste'
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>

    </Segment>
    </>)
}