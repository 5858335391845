import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button, Form, Header, Icon, Segment } from 'semantic-ui-react';

import { useAuth } from '../Auth.context';
import { UserRoles } from '../Enums';

export default function PageConnexion (props) {

    const { onLogin } = useAuth();
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [accessLevel, setAccessLevel] = useState(UserRoles.TRANSPORTEUR);

    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        setLoading(true);

        //TODO check fields, remove timeout and request backend
        setTimeout(() => {
            setLoading(false);

            onLogin(accessLevel);

            switch (accessLevel) {
                case UserRoles.DOCKER_COMMIS:
                    navigate('/inspection');
                    break;
                default:
                    navigate('/');
                    break;
            }
        }, 1500)
    }

    return (<div className='centerDiv'>
        <Header 
        inverted 
        icon
        size='huge' >
            <Icon name='users' circular />
            <Header.Content>
                Connexion
                <Header.Subheader>Page de connexion au portail web de prise de rendez-vous à TNMSC</Header.Subheader>
            </Header.Content>
        </Header>

        <Segment style={{ width: '400px' }}>

            <Form 
            fluid 
            loading={loading}
            size='large'>

                <Form.Select
                label="Niveau d'accès : (dev)"
                options={Object.keys(UserRoles).map((role) => {
                    return { key: UserRoles[role], value: UserRoles[role], text: role }
                })}
                defaultValue={UserRoles.TRANSPORTEUR}
                value={accessLevel}
                onChange={(e, { value }) => { setAccessLevel(value); }}
                />

                <Form.Input
                name='username'
                label="Nom d'utilisateur :"
                type='text'
                placeholder="Nom d'utilisateur"
                value={username}
                onChange={(e, { value }) => { setUsername(value); }} />

                <Form.Input 
                name='password'
                label='Mot de passe :'
                type='password'
                placeholder='********'
                value={password}
                onChange={(e, { value }) => { setPassword(value); }} />

                <Button
                type='submit'
                fluid
                icon
                positive
                size='large'
                labelPosition='left'
                onClick={handleSubmit} >
                    <Icon name='sign-in' />
                    Connexion
                </Button>
                
            </Form>

            <div className='connexion-links'>
                <Link to='/mot-de-passe-oublie'>Mot de passe oublié ?</Link>
                <Link to='/inscription'>Inscription</Link>
            </div>
        </Segment>
    </div>)
}