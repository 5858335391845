import { Navigate } from "react-router-dom";

import { useAuth } from "./Auth.context"

import { allUserRoles } from "./Enums";

// for auth tuto : https://www.robinwieruch.de/react-router-authentication/

const ProtectedRoute = ({ children, accessLevels = allUserRoles() }) => {
    const { user } = useAuth();

    if(!user || !user.connected){
        return <Navigate to='/connexion' replace />
    }

    if(!accessLevels.includes(user.accessLevel)){
        return <Navigate to='/' replace />
    }

    return children
}

export { ProtectedRoute };