import React, { useState } from 'react';

import { Header, Segment, Icon, Form, Grid, Select } from 'semantic-ui-react';

import { fakeUsers } from '../FakeData';

export default function GestionUtilisateurs (props) {

    const [users, setUsers] = useState(fakeUsers);

    const [selectedUser, setSelectedUser] = useState({ 
        id: null,
        nom: null, 
        prenom: null, 
        phone: null,
        email: null,
        password: null
    });

    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [phone, setPhone] = useState('');
    const [identifiant, setIdentifiant] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    return (<>

        <Header attached='top' size='huge'>
            <Icon name='users' />
            Gestion utilisateurs
        </Header>

        <Segment attached> 

            <Form fluid>
                <Form.Group widths='equal' style={{ marginBottom: 0 }}>

                    <Form.Input
                    type='text'
                    label="Nom d'utilisateur :"
                    placeholder="Nom d'utilisateur"
                    />

                    <Form.Input
                    type='password'
                    label='Mot de passe :'
                    placeholder='********'
                    />

                    <Form.Input
                    type='password'
                    label='Confirmez mot de passe :'
                    placeholder='********'
                    />

                    <Form.Button
                    floated='right'
                    icon='plus'
                    labelPosition='left'
                    positive
                    content='Ajouter utilisateur'
                    style={{ marginTop: '20px' }}
                    />

                </Form.Group>
            </Form>

        </Segment>

        <Segment attached padded>

            <Grid columns={2} divided>

                <Grid.Row>

                    <Grid.Column width={4}>

                        <Header size='tiny'>Liste des utilisateurs : </Header>

                        <Select
                        options={users.map((user) => { return { key: user.id, value: user.id, text: (user.prenom + ' ' + user.nom) } })}
                        placeholder='Utilisateurs :'
                        value={selectedUser.id}
                        onChange={(e, { value }) => { 
                            let userObj = users.find(usr => usr.id === value);
                            if(userObj) setSelectedUser(userObj) 
                        }}
                        />

                    </Grid.Column>

                    <Grid.Column width={12}>

                        <Header attached='top'>
                            <Icon name='id card outline' />
                            Informations personnelles
                        </Header>

                        <Segment attached>

                            <Form>

                                <Form.Group widths='equal'>

                                    <Form.Input
                                    type='text'
                                    label='Nom :'
                                    placeholder='Nom'
                                    value={selectedUser.nom}
                                    />

                                    <Form.Input
                                    type='text'
                                    label='Prénom :'
                                    placeholder='Prénom'
                                    value={selectedUser.prenom}
                                    />

                                    <Form.Input
                                    type='phone'
                                    label='Numéro de téléphone :'
                                    placeholder='06XXXXXXXX'
                                    value={selectedUser.phone}
                                    />

                                </Form.Group>

                                <Form.Group widths='equal'>

                                    <Form.Input
                                    type='email'
                                    label='Identifiant :'
                                    placeholder='Identifiant'
                                    value={selectedUser.email}
                                    />

                                    <Form.Input
                                    type='password'
                                    label='Mot de passe :'
                                    placeholder='********'
                                    value={password}
                                    />

                                    <Form.Input
                                    type='password'
                                    label='Confirmez mot de passe :'
                                    placeholder='********'
                                    value={passwordConfirm}
                                    />
                                
                                </Form.Group>

                                <Form.Button
                                icon='lock'
                                labelPosition='left'
                                content='Modifier'
                                />

                            </Form>

                        </Segment>

                    </Grid.Column>

                </Grid.Row>
            </Grid>

        </Segment>

    </>)
}