import React, { useState, useEffect } from 'react';

import { Header, Segment, Icon, Menu, Input, Label, Table, Button, Message } from 'semantic-ui-react';

import { fakeRdvEntree } from '../FakeData';
import { reducer } from '../Utils';

import { useAuth } from '../Auth.context';
import { UserRoles } from '../Enums';

import ContainerValidator from 'container-validator';

export default function PageListesDattente (props) {

    const { user } = useAuth();

    const validator = new ContainerValidator();

    const [list, setList] = useState([]);

    const [ctr, setCtr] = useState('');

    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [ctrChecked, setCtrChecked] = useState(false);
    const [ctrError, setCtrError] = useState(false);

    const [state, dispatch] = React.useReducer(reducer, {
        column: null,
        data: list,
        direction: null,
    });
    const { column, data, direction } = state;

    const handleCtrType = (e, { value }) => {

        value = value.toUpperCase()

        if(value.length > 11){
            e.preventDefault();
        }else if(value.length === 11){
            setCtr(value)

            if(validator.isValid(value)){
                setCtrError(false);
                setLoading(true);

                setTimeout(() => {
                    setLoading(false);
                    setCtrChecked(true);
                }, 1500)
            }else{
                setCtrError(true);
                setCtrChecked(false);
            }
        }else{
            setCtr(value);

            if(ctrError) setCtrError(false);
            if(ctrChecked) setCtrChecked(false);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        //TODO fetch list from backend
    });
    
    return (<>
    <Header 
    size='huge'
    inverted>
        <Icon name='hourglass half' />
        <Header.Content>
            Listes d'attente
            <Header.Subheader>
                Listes de conteneurs en attente d'acceptation à TNMSC
            </Header.Subheader>
        </Header.Content>
    </Header>

    <Segment style={{ display: 'flex' }}>

        <Menu 
        vertical 
        style={{ width: '310px', marginBottom: 0 }}>

            <Menu.Item>
                <Header>Séléctionnez un shift : </Header>
            </Menu.Item>

            <Menu.Item
            name={0}
            active={activeTab === 0}
            onClick={(e) => { setActiveTab(0); }} >
                <Label>34</Label>
                Mercredi 26 - Matin
            </Menu.Item>

            <Menu.Item
            name={1}
            active={activeTab === 1}
            onClick={(e) => { setActiveTab(1); }} >
                <Label>32</Label>
                Mercredi 26 - Après-midi
            </Menu.Item>

            <Menu.Item
            name={2}
            active={activeTab === 2}
            onClick={(e) => { setActiveTab(2); }} >
                <Label>14</Label>
                Jeudi 27 - Matin
            </Menu.Item>

            <Menu.Item
            name={3}
            active={activeTab === 3}
            onClick={(e) => { setActiveTab(3); }} >
                <Label>0</Label>
                Jeudi 27 - Après-midi
            </Menu.Item>

            {user.accessLevel <= UserRoles.TRANSPORTEUR_ADMIN &&
                <Menu.Item>
                    <Header size='tiny'>Ajouter un conteneur : </Header>

                    <Input
                    type='text'
                    name='ctr'
                    placeholder='Numéro de conteneur'
                    action={{
                        icon: 'calendar plus',
                        color: 'yellow',
                        disabled: !ctrChecked
                    }}
                    icon={ctrChecked ? 'check' : 'search'}
                    iconPosition='left'
                    actionPosition='right'
                    value={ctr}
                    onChange={handleCtrType}
                    loading={loading}
                    error={ctrError}
                    />
                    {ctrError && <Message negative style={{ padding: '10px' }}>Numéro invalide</Message>}
                </Menu.Item>
            }
        </Menu>

        <Table
        celled
        striped
        selectable
        sortable
        definition={user.accessLevel >= UserRoles.DOCKER_CHEF_PARC}
        textAlign='center'
        style={{ 
            marginTop : 0, 
            marginLeft: '15px ',
            alignSelf: 'flex-start',
        }} >
            <Table.Header fullWidth>

                <Table.Row>
                    <Table.HeaderCell colSpan={user.accessLevel >= UserRoles.DOCKER_CHEF_PARC ? 8 : 7} >
                        <Header>Liste {user.accessLevel <= UserRoles.TRANSPORTEUR_ADMIN ? 'de vos' : 'des'} conteneurs en attente du Mercredi 26 Octobre 2022 - Matin</Header>
                    </Table.HeaderCell>
                </Table.Row>

                <Table.Row>
                    {user.accessLevel >= UserRoles.DOCKER_CHEF_PARC && <Table.HeaderCell>Ajout</Table.HeaderCell> }
                    <Table.HeaderCell
                    sorted={column === 'ctr' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'ctr' })}>
                        Conteneur
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={column === 'sens' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'sens' })}>
                        Sens
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={column === 'iso' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'iso' })}>
                        ISO
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={column === 'etat' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'etat' })}>
                        Etat
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={column === 'navire' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'navire' })}>
                        Navire
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={column === 'closing' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'closing' })}>
                        Date de fermeture
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Remarque
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>

                <Table.Row>
                    {user.accessLevel >= UserRoles.DOCKER_CHEF_PARC && <Table.Cell>
                        <Button
                        icon='calendar plus'
                        iconPosition='left'
                        color='yellow'
                        size='mini'
                        />
                    </Table.Cell>}
                    <Table.Cell>{fakeRdvEntree.ctr}</Table.Cell>
                    <Table.Cell>Entrée</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.iso}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.etat}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.navire}</Table.Cell>
                    <Table.Cell>29/10/2022 14:00</Table.Cell>
                    <Table.Cell></Table.Cell>
                </Table.Row>
                
            </Table.Body>
        </Table>
    </Segment>

    </>)
}