import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Icon } from 'semantic-ui-react';

import { useAuth } from '../Auth.context';

export default function PageDeconnexion (props) {

    const navigate = useNavigate();
    const { onLogout } = useAuth();

    useEffect(() => {
        setTimeout(() => {
            onLogout();
            navigate('/connexion');
        }, 2000)
    })

    return (<div className='centerDiv'>
        <Header 
        inverted 
        icon
        size='huge' >
            <Icon name='sign-out' circular />
            <Header.Content>
                Deconnexion
                <Header.Subheader>Vous allez être deconnecté du portail web de prise de rendez-vous à TNMSC</Header.Subheader>
            </Header.Content>
        </Header>
    </div>)
}