import React from 'react'

import { Popup, Table } from 'semantic-ui-react'

import CreneauComponent from './creneau.component'

import { useAuth } from '../Auth.context';
import { UserRoles } from '../Enums';

export default function CalendrierComponent ({ jours, creneaux }) {

    const { user } = useAuth();

    let capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (<>
        <Table 
        celled 
        fluid 
        textAlign='center' 
        columns={jours.length} 
        size='large'>

            <Table.Header fullWidth>
                <Table.Row>
                    {jours.map((jour) => 
                        <Table.HeaderCell>{
                            capitalizeFirstLetter(new Date(jour.date).toLocaleDateString('Fr-fr', { weekday: 'long', day: 'numeric' }))
                        }</Table.HeaderCell>
                    )}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {creneaux.map((fauxCreneau, index) => { 
                    return <Table.Row>

                        {jours.map((jour) => {
                            let creneau
                            if(index < jour.creneaux.length) {
                                creneau = jour.creneaux[index];
                            }

                            if(!creneau) return <Table.Cell></Table.Cell> // Cellule vide si créneau pas ouvert

                            if(fauxCreneau.heure === creneau.heure){

                                return <CreneauComponent jour={jour} creneau={creneau} />

                            }else{
                                return <Table.Cell></Table.Cell> // Cellule vide si créneau pas ouvert
                            }
                        })}
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
    </>)
}