import { UserRoles } from './Enums';

let fakeUser = {
    connected: false,
    accessLevel: UserRoles.DOCKER_RESPONSABLE,
};

let fakeUsers = [
    {
        id: 1,
        nom: 'Dupont',
        prenom: 'Bertrand',
        phone: '0678456726',
        email: 'bertrand.dupont@example.com',
        password: Date.now(),
    },
    {
        id: 2,
        nom: 'Dupains',
        prenom: 'Gerad',
        phone: '0798276746',
        email: 'dupainsgerard@example.com',
        password: Date.now(),
    },
    {
        id: 3,
        nom: 'Legrand',
        prenom: 'Xavier',
        phone: '0612763654',
        email: 'xavlegrand@example.com',
        password: Date.now(),
    },
]

let jours = [
    {
        date: Date.parse('24 Oct 2022'), // Lundi
        creneaux : [
            { 
                bloque: false,
                heure: 6,
                capacite: 50,
                faits: 50,
                reserves: 50,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 7,
                capacite: 60,
                faits: 60,
                reserves: 60,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 8,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 2,
            },
            { 
                bloque: false,
                heure: 9,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 10,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 1,
            },
            { 
                bloque: false,
                heure: 11,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 12,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 13,
                capacite: 60,
                faits: 59, //noshow
                reserves: 60,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 14,
                capacite: 50,
                faits: 50,
                reserves: 50,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 15,
                capacite: 60,
                faits: 60,
                reserves: 60,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 16,
                capacite: 80,
                faits: 78, // 2 noshow
                reserves: 80,
                pris: 1,
            },
            { 
                bloque: false,
                heure: 17,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 18,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 2,
            },
            { 
                bloque: false,
                heure: 19,
                capacite: 60,
                faits: 60,
                reserves: 60,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 20,
                capacite: 50,
                faits: 50,
                reserves: 50,
                pris: 0,
            },
        ],
    },
    {
        date: Date.parse('25 Oct 2022'), // Mardi
        creneaux : [
            { 
                bloque: false,
                heure: 6,
                capacite: 50,
                faits: 50,
                reserves: 50,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 7,
                capacite: 60,
                faits: 60,
                reserves: 60,
                pris: 1,
            },
            { 
                bloque: false,
                heure: 8,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 9,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 10,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 11,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 1,
            },
            { 
                bloque: false,
                heure: 12,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 13,
                capacite: 60,
                faits: 59, // 1 annulé
                reserves: 60,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 14,
                capacite: 50,
                faits: 50,
                reserves: 50,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 15,
                capacite: 60,
                faits: 60,
                reserves: 60,
                pris: 2,
            },
            { 
                bloque: false,
                heure: 16,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 17,
                capacite: 80,
                faits: 78,
                reserves: 78,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 18,
                capacite: 80,
                faits: 80,
                reserves: 80,
                pris: 1,
            },
            { 
                bloque: false,
                heure: 19,
                capacite: 60,
                faits: 60,
                reserves: 60,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 20,
                capacite: 50,
                faits: 50,
                reserves: 50,
                pris: 0,
            },
        ],
    },
    {
        date: Date.parse('26 Oct 2022'), // Mercredi
        creneaux : [
            { 
                bloque: false,
                heure: 6,
                capacite: 50,
                faits: 50,
                reserves: 50,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 7,
                capacite: 60,
                faits: 60,
                reserves: 60,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 8,
                capacite: 80,
                faits: 67,
                reserves: 80,
                pris: 2,
            },
            { 
                bloque: false,
                heure: 9,
                capacite: 80,
                faits: 23,
                reserves: 80,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 10,
                capacite: 80,
                faits: 0,
                reserves: 80,
                pris: 1,
            },
            { 
                bloque: false,
                heure: 11,
                capacite: 80,
                faits: 0,
                reserves: 68,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 12,
                capacite: 80,
                faits: 0,
                reserves: 62,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 13,
                capacite: 60,
                faits: 0,
                reserves: 58,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 14,
                capacite: 50,
                faits: 0,
                reserves: 45,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 15,
                capacite: 60,
                faits: 0,
                reserves: 60,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 16,
                capacite: 80,
                faits: 0,
                reserves: 52,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 17,
                capacite: 80,
                faits: 0,
                reserves: 41,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 18,
                capacite: 80,
                faits: 0,
                reserves: 33,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 19,
                capacite: 60,
                faits: 0,
                reserves: 22,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 20,
                capacite: 50,
                faits: 0,
                reserves: 25,
                pris: 0,
            },
        ],
    },
    {
        date: Date.parse('27 Oct 2022'), // Jeudi
        creneaux : [
            { 
                bloque: false,
                heure: 6,
                capacite: 50,
                faits: 0,
                reserves: 3,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 7,
                capacite: 60,
                faits: 0,
                reserves: 15,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 8,
                capacite: 80,
                faits: 0,
                reserves: 7,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 9,
                capacite: 80,
                faits: 0,
                reserves: 0,
                pris: 1,
            },
            { 
                bloque: false,
                heure: 10,
                capacite: 80,
                faits: 0,
                reserves: 0,
                pris: 1,
            },
            { 
                bloque: false,
                heure: 11,
                capacite: 80,
                faits: 0,
                reserves: 2,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 12,
                capacite: 80,
                faits: 0,
                reserves: 1,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 13,
                capacite: 60,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 14,
                capacite: 50,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 15,
                capacite: 60,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 16,
                capacite: 80,
                faits: 0,
                reserves: 3,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 17,
                capacite: 80,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 18,
                capacite: 80,
                faits: 0,
                reserves: 10,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 19,
                capacite: 60,
                faits: 0,
                reserves: 6,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 20,
                capacite: 50,
                faits: 0,
                reserves: 1,
                pris: 0,
            },
        ],
    },
    {
        date: Date.parse('28 Oct 2022'), // Vendredi
        creneaux : [
            { 
                bloque: false,
                heure: 6,
                capacite: 50,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 7,
                capacite: 60,
                faits: 0,
                reserves: 15,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 8,
                capacite: 80,
                faits: 0,
                reserves: 7,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 9,
                capacite: 80,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 10,
                capacite: 80,
                faits: 0,
                reserves: 2,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 11,
                capacite: 80,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 12,
                capacite: 80,
                faits: 0,
                reserves: 8,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 13,
                capacite: 60,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 14,
                capacite: 50,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 15,
                capacite: 60,
                faits: 0,
                reserves: 9,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 16,
                capacite: 80,
                faits: 0,
                reserves: 3,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 17,
                capacite: 80,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 18,
                capacite: -1,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 19,
                capacite: -1,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
            { 
                bloque: false,
                heure: 20,
                capacite: -1,
                faits: 0,
                reserves: 0,
                pris: 0,
            },
        ],
    },
]

let escales = [
    {
        vos: 169890,
        navire: 'MSC CORNELIA',
        eta: Date.parse('25 Oct 2022 22:00'),
        statut: 'À quai',
        ouvertureDry: Date.parse('18 Oct 2022 14:00'),
        ouvertureReefer: Date.parse('21 Oct 2022 14:00'),
        fermeture: Date.parse('25 Oct 2022 20:00'),
    },
    {
        vos: 169842,
        navire: 'MSC ALICANTE',
        eta: Date.parse('26 Oct 2022 12:00'),
        statut: 'Rade',
        ouvertureDry: Date.parse('19 Oct 2022 14:00'),
        ouvertureReefer: Date.parse('22 Oct 2022 14:00'),
        fermeture: Date.parse('26 Oct 2022 11:00'),
    },
    {
        vos: 169816,
        navire: 'MSC ADELAIDE',
        eta: Date.parse('29 Oct 2022 15:00'),
        statut: 'PVA',
        ouvertureDry: Date.parse('22 Oct 2022 07:00'),
        ouvertureReefer: Date.parse('25 Oct 2022 07:00'),
        fermeture: Date.parse('29 Oct 2022 14:00'),
    },
    {
        vos: 170061,
        navire: 'MSC ABIGAIL F',
        eta: Date.parse('30 Oct 2022 07:00'),
        statut: 'PVA',
        ouvertureDry: Date.parse('23 Oct 2022 07:00'),
        ouvertureReefer: Date.parse('26 Oct 2022 07:00'),
        fermeture: Date.parse('29 Oct 2022 21:00'),
    },
]

let fakeRdvEntree = {
    date: Date.now(),
    no: 1098798798,
    statut: 'RDV pris',
    sens: 'entree',
    transporteur: 'Buffard',
    ctr: 'TEXU3070079',
    etat: 'Plein',
    ref: 'LEH0098765',
    iso: '45G1',
    navire: 'MSC ADELAIDE'
}

let fakeRdvSortie = {
    date: Date.now(),
    no: 1098798976,
    statut: 'RDV non honoré',
    sens: 'sortie',
    transporteur: 'Duboc',
    ctr: 'MRKU3162349',
    etat: 'Plein',
    ref: 'LEH0076987',
    iso: '22U1',
    navire: 'EMMA MAERSK'
}

export { fakeUser, fakeUsers, jours, escales, fakeRdvEntree, fakeRdvSortie };