// UI & CSS
import 'semantic-ui-css/semantic.min.css';
import './App.css';

// React
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Date Picker
import fr from 'date-fns/locale/fr';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

// Components
import NavBar from './components/navBar.component';
import Footer from './components/footer.component';
import StatsHeader from './components/statsHeader.component';

// Common Pages
import Page404 from './pages/404.page';
import PageConnexion from './pages/connexion.page';
import PageDeconnexion from './pages/deconnexion.page';
import PageInscription from './pages/inscription.page'

// Transporteur Pages
import PageCalendrier from './pages/calendrier.page';
import PageCreneau from './pages/creneau.page';
import PageEscales from './pages/escales.page';
import PageRendezVous from './pages/rendezVous.page';
import PageListesDattente from './pages/listesDattente.page';
import PageEspaceClient from './pages/transporteur/espaceClient.page';

// Inspection Page
import PageInspection from './pages/inspection/inspection.page';

// Responsable Pages
import PageInterrogation from './pages/responsable/interrogation.page';
import PageStatistiques from './pages/responsable/statistiques.page';

import AuthProvider from './Auth.provider';
import { ProtectedRoute as P }  from './ProtectedRoute';
import { UserRoles, allUserRolesExcept, onlyTransporteurs, rolesSuperiorOrEqualTo } from './Enums';

export default function App() {

  // Enregistrement de la langue et format de dates pour le datePicker
  registerLocale('fr', fr);
  setDefaultLocale('fr');

  return (

    <Router>

      <AuthProvider>

        <NavBar />

        <div className='wrapper'>

          <StatsHeader />

          <Routes>
            { /* ------------------------------ Common ------------------------------------ */ }
            <Route path='/' element={<P accessLevels={allUserRolesExcept([UserRoles.DOCKER_COMMIS])}><PageCalendrier /></P> } />
            <Route path='/connexion' element={<PageConnexion /> } />
            <Route path='/deconnexion' element={<PageDeconnexion /> } />
            <Route path='/inscription' element={<PageInscription /> } />

            { /* ------------------------------ Transporteur ------------------------------------ */ }
            <Route path='/creneau' element={<P accessLevels={onlyTransporteurs()}><PageCreneau /></P>} />
            <Route path='/escales' element={<P accessLevels={allUserRolesExcept([UserRoles.DOCKER_COMMIS])}><PageEscales /></P> } />
            <Route path='/mes-rendez-vous' element={<P accessLevels={onlyTransporteurs()}><PageRendezVous /></P> } />
            <Route path='/listes-d-attente' element={<P  accessLevels={allUserRolesExcept([UserRoles.DOCKER_COMMIS])}><PageListesDattente /></P> } />
            <Route path='/espace-client' element={<P accessLevels={onlyTransporteurs()}><PageEspaceClient /></P> } />

            { /* ------------------------------ Inspection --------------------------------------- */ }
            <Route path='/inspection' element={<P accessLevels={[UserRoles.DOCKER_COMMIS]}><PageInspection /></P> } />
            
            { /* ------------------------------ Responsable -------------------------------------- */ }
            <Route path='/interrogation' element={<P accessLevels={rolesSuperiorOrEqualTo(UserRoles.DOCKER_HELPDESK)}> <PageInterrogation /></P>} />
            <Route path='/statistiques' element={<P accessLevels={rolesSuperiorOrEqualTo(UserRoles.DOCKER_HELPDESK)} ><PageStatistiques /></P> } />

            <Route path='*' element={<Page404 /> } />
          </Routes>
        </div>

        <Footer />

      </AuthProvider>

    </Router>
  );
}