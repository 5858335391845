import React from "react";
import { Checkbox, Form, Grid, Header, Input } from "semantic-ui-react";

export default function PopupCreneauComponent ({ creneau }) {

    return (<>
        <Header size='large'>Paramètres du créneau : </Header>

        <Form>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Form.Field>
                            <label>Ouvert ?</label>
                            <Checkbox
                            toggle
                            checked={creneau.capacite > 0}
                            style={{ marginTop: '10px' }} />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Form.Input
                        label='Capacité'
                        type='number'
                        value={creneau.capacite}
                        placeholder='90'
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Form.Field>
                            <label>Bloqué ?</label>
                            <Checkbox
                            toggle
                            checked={creneau.bloque}
                            style={{ marginTop: '10px' }} />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Form.Input
                        label='% Vides'
                        type='number'
                        placeholder='20'
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    </>)
}