import React, { useState, useEffect } from 'react';

import { Header, Segment, Icon, Table, Menu, Input, Select, Dropdown, Label, Pagination, Popup, Button, Message } from 'semantic-ui-react';
import PopupDates from '../components/popupDates.component';

import { fakeRdvEntree, fakeRdvSortie } from '../FakeData';
import { formatDate, reducer } from '../Utils';

export default function PageRendezVous (props) {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());//TODO la faire demarer 1H apres startDate

    let [list, setList] = useState([]);

    const [state, dispatch] = React.useReducer(reducer, {
        column: null,
        data: list,
        direction: null,
    });
    const { column, data, direction } = state;

    let handleStartDateChange = (date) => {

        setStartDate(date);

        //TODO fetch new results
    }

    let handleEndDateChange = (date) => {
        //TODO verifier quelle n'est pas plus ancienne que la date de départ
        setEndDate(date);

        //TODO fetch new results
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        //TODO fetch list from backend
    });
    
    return (<>
    <Header 
    size='huge'
    inverted>
        <Icon name='calendar check outline' />
        <Header.Content>
            Mes rendez-vous
            <Header.Subheader>
                Liste de vos rendez-vous prévus à TNMSC
            </Header.Subheader>
        </Header.Content>
    </Header>

    <Segment>

        <Message fluid icon>
            <Icon name='info circle' />
            <Message.Content>
                <Message.Header>Informations</Message.Header>
                <Message.List>
                    <Message.Item>
                        Par defaut la page 'Mes rendez-vous' affiche les rendez-vous à venir, mais vous pouvez régler l'intervalle de temps qui vous interesse avec les champs de dates, et trier les resultats avec les options mises à votre disposition.
                    </Message.Item>
                    <Message.Item>
                        Vous pouvez aussi rechercher un numéro de conteneur en particulier avec le bouton rechercher à droite.
                    </Message.Item>
                </Message.List>
            </Message.Content>
        </Message>

        <Menu 
        fluid 
        attached='top' 
        compact
        size='small'
        stackable>
            <Menu.Item>
                <PopupDates 
                startDate={startDate} 
                endDate={endDate} 
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange} />
            </Menu.Item>

            <Menu.Menu position='right'>

                <Menu.Item>
                    <Select
                    options={[
                        { key: 'all', value: 'all', text: 'Toutes tailles' },
                        { key: '20', value: '20', text: "20 pieds" },
                        { key: '40', value: '40', text: "40 pieds" },
                        { key: '45', value: '45', text: '45 pieds' }
                    ]}
                    compact
                    defaultValue='all'
                    />
                </Menu.Item>

                <Menu.Item>
                    <Select
                    options={[
                        { key: 'all', value: 'all', text: 'Tout types' },
                        { key: 'dry', value: 'dry', text: 'Dry' },
                        { key: 'reefer', value: 'reefer', text: 'Reefer' },
                        { key: 'opentop', value: 'opentop', text: 'Open Top' },
                        { key: 'flat', value: 'flat', text: 'Flat' }
                    ]}
                    compact
                    defaultValue='all'
                    />
                </Menu.Item>

                <Menu.Item>
                    <Select
                    options={[
                        { key: 'all', value: 'all', text: 'Pl / Vi' },
                        { key: 'vide', value: 'vide', text: 'Vides' },
                        { key: 'plein', value: 'plein', text: 'Pleins' }
                    ]}
                    compact
                    defaultValue='all'
                    />
                </Menu.Item>

                <Menu.Item>
                    <Dropdown
                    selection
                    compact
                    text='Statuts de RDV :' >
                        <Dropdown.Menu>
                            <Dropdown.Item>
                                <Label>RDV pris</Label>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Label color='teal'>RDV validé</Label>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Label color='black'>RDV annulé</Label>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Label color='red'>RDV non honoré</Label>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>

                <Menu.Item>
                    <Popup
                    on='click'
                    position='bottom right'
                    trigger= {
                        <Button
                        icon='search' />
                    }>
                        <Input
                        type='text'
                        name='ctr'
                        icon='search'
                        iconPosition='right'
                        placeholder='No de conteneur' />
                    </Popup>
                </Menu.Item>

            </Menu.Menu>

        </Menu>

        <Table 
        celled 
        fluid
        striped
        selectable
        sortable
        padded
        textAlign='center'
        attached='bottom'>

            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell
                    sorted={column === 'date' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'date' })}>
                        Date et créneau
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={column === 'rdv' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'rdv' })}>
                        Numéro de RDV
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={column === 'statut' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'statut' })}>
                        Statut de RDV
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={column === 'ctr' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'ctr' })}>
                        Numéro de CTR
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Code ISO
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        État
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={column === 'ref' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'ref' })}>
                        Référence
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={column === 'navire' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'navire' })}>
                        Navire
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            {
                //TODO iterate with backend fetched list, and ribbons for rdv statuts
            }
            <Table.Body>
                <Table.Row>
                    <Table.Cell>{formatDate(fakeRdvEntree.date)}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.no}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.statut}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.ctr}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.iso}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.etat}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.ref}</Table.Cell>
                    <Table.Cell>{fakeRdvEntree.navire}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>{formatDate(fakeRdvSortie.date)}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.no}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.statut}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.ctr}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.iso}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.etat}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.ref}</Table.Cell>
                    <Table.Cell>{fakeRdvSortie.navire}</Table.Cell>
                </Table.Row>
            </Table.Body>

            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell colSpan={8}>
                        <Pagination defaultActivePage={5} totalPages={10} />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    </Segment>
    </>)
}