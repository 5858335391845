import React from 'react'
import { Link } from 'react-router-dom';

import { Icon } from 'semantic-ui-react';

import logo from '../assets/images/logo.jpg';

import { UserRoles } from '../Enums';
import { useAuth } from '../Auth.context';

export default function NavBar (props) {

    const { user } = useAuth();

    let navLinks = [];

    switch (user.accessLevel) {

        case UserRoles.TRANSPORTEUR:
        case UserRoles.TRANSPORTEUR_ADMIN:
            navLinks = [
                { title: "Calendrier", href: '/' },
                { title: "Escales", href: '/escales' },
                { title: "Rendez-vous", href: '/mes-rendez-vous' },
                { title: "Listes d'attente", href: '/listes-d-attente' },
            ]
        break;
        case UserRoles.DOCKER_HELPDESK:
        case UserRoles.DOCKER_CHEF_PARC: 
        case UserRoles.DOCKER_RESPONSABLE:
            navLinks = [
                { title: 'Calendrier', href: '/' },
                { title: 'Escales', href: '/escales' },
                { title: "Listes d'attente", href: '/listes-d-attente' },
                { title: 'Interrogation', href: '/interrogation' },
                { title: 'Statistiques', href: '/statistiques' }
            ]
        default:
        break;
    }

    // Bouton addition à côté du bouton de deconnexion, change en fonction du rôle de l'utilisateur
    let additionalNavButton;

    if(user.accessLevel <= UserRoles.TRANSPORTEUR_ADMIN) { 

        additionalNavButton = <Link to='/espace-client'><Icon name='user circle'></Icon></Link>;

    }else if (user.accessLevel >= UserRoles.DOCKER_CHEF_PARC){

        additionalNavButton = <Link to='/ouverture-journee'><Icon name='plus square'></Icon></Link>;

    }

    return (
        <nav>
            <div className='nav-logo'>
            <img src={logo} alt='Logo TN' />
            <h1 className='titre'>RDV<br /> TNMSC</h1>
            </div>

            <div className='nav-links'>
                {user.connected && navLinks.map((link) => <Link to={link.href}>{link.title}</Link>)}
            </div>

            <div className='nav-buttons'>
                {user.connected && 
                    <Link to='/deconnexion'>
                        <Icon name='power' /> 
                    </Link>
                }
                {user.connected && 
                    additionalNavButton
                }
            </div>
        </nav>
    );
}