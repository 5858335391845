import React, { useEffect } from 'react';

import { Header, Segment, Table, Icon, Input } from 'semantic-ui-react';

import EscaleComponent from '../components/escale.component';

import { escales } from '../FakeData';
import { reducer } from '../Utils';
import { useAuth } from '../Auth.context';

export default function PageEscales (props) {

    const { user } = useAuth()

    //TODO système de notification quand ajout d'une escale

    const [state, dispatch] = React.useReducer(reducer, {
        column: null,
        data: escales,
        direction: null,
    });
    const { column, data, direction } = state;

    useEffect(() => {
        window.scrollTo(0, 0);

        //TODO fetch escales from backend
    });

    return (<>
    <Header 
    size='huge'
    inverted>
        <Icon name='ship' />
        <Header.Content>
            Escales navires
            <Header.Subheader>
                Liste des escales navire à TNMSC
            </Header.Subheader>
        </Header.Content>
    </Header>

    <Segment.Group>
        <Segment>

            <Table 
            celled 
            fluid 
            striped 
            selectable 
            sortable
            padded 
            textAlign='center'>

                <Table.Header>
                    <Table.Row>

                        <Table.HeaderCell
                        sorted={column === 'vos' ? direction : null}
                        onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'vos' })}>
                            Numéro VOS
                        </Table.HeaderCell>

                        <Table.HeaderCell
                        sorted={column === 'navire' ? direction : null}
                        onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'navire' })}>
                            Navire
                        </Table.HeaderCell>

                        <Table.HeaderCell
                        sorted={column === 'eta' ? direction : null}
                        onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'eta' })}>
                            ETA
                        </Table.HeaderCell>

                        <Table.HeaderCell
                        sorted={column === 'statut' ? direction : null}
                        onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'statut' })}>
                            Statut
                        </Table.HeaderCell>

                        <Table.HeaderCell
                        sorted={column === 'ouvertureDry' ? direction : null}
                        onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'ouvertureDry' })}>
                            Ouverture gate Dry
                        </Table.HeaderCell>

                        <Table.HeaderCell
                        sorted={column === 'ouvertureReefer' ? direction : null}
                        onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'ouvertureReefer' })}>
                            Ouverture gate RF
                        </Table.HeaderCell>

                        <Table.HeaderCell
                        sorted={column === 'fermeture' ? direction : null}
                        onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'fermeture' })}>
                            Fermeture gate
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data.map((escale) => {
                        return(<EscaleComponent escale={escale} user={user} />)
                    })}
                </Table.Body>

            </Table>

        </Segment>
    </Segment.Group>
    </>)
}