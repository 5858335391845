import _ from 'lodash';

let formatDate = function (date){
    return new Date(date).toLocaleDateString('Fr-fr', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
}

let formatFancyDate = function (date){
    return new Date(date).toLocaleDateString('Fr-fr', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })
}

// Permet le tri des données dans les tableaux
let reducer = function (state, action) {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending',
          }
        }
  
        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: 'ascending',
        }
      default:
        throw new Error()
    }
}

export { formatDate, formatFancyDate, reducer };