import React from 'react';
import { useLocation } from 'react-router-dom';

import { Header, Progress, Segment, Statistic } from 'semantic-ui-react';

import { useAuth } from '../Auth.context';
import { UserRoles } from '../Enums';

export default function StatsHeader (props) {

    const location = useLocation();
    const { user } = useAuth();

    if(!user.connected) return (<></>)
    if(user.accessLevel < UserRoles.DOCKER_COMMIS) return (<></>)

    if(location.pathname === '/deconnexion') return (<></>)

    return (<>
    <Segment raised >

        <div className='stats-header'>
            <Header>Statistiques de la journée : </Header>

            <Statistic.Group 
            widths='equal'
            size='mini' 
            style={{ 
                margin: 0,
                justifyContent: 'space-between'
            }}>

                <Statistic horizontal style={{ marginRight: 0 }}>{ /* À appliquer sur le first child */ }
                    <Statistic.Value>
                        1789
                    </Statistic.Value>
                    <Statistic.Label>Rendez-vous</Statistic.Label>
                </Statistic>

                <Statistic horizontal color='green'>
                    <Statistic.Value>
                        845
                    </Statistic.Value>
                    <Statistic.Label style={{ color: '#21ba45' }}>effectués</Statistic.Label>
                </Statistic>

                <Statistic horizontal color='orange'>
                    <Statistic.Value>
                        {1789-845}
                    </Statistic.Value>
                    <Statistic.Label style={{ color: '#f2711c' }}>restants</Statistic.Label>
                </Statistic>

                <Statistic horizontal color='red'>
                    <Statistic.Value>
                        2
                    </Statistic.Value>
                    <Statistic.Label style={{ color: '#db2828' }}>non honorés</Statistic.Label>
                </Statistic>
            </Statistic.Group>
        </div>

        

        <Progress
        active
        color='blue'
        percent={42} >
            845 / 1789 RDV
        </Progress>

    </Segment>
    </>)
}