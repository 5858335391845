import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

export default function Page404 (props) {

    return (<div className='centerDiv'>
    <Header
    icon
    size='huge'
    inverted>
        <Icon name='times circle' />
        Page introuvable
        <Header.Subheader>
            Il semblerait que la page que vous recherchez n'éxiste pas
        </Header.Subheader>
    </Header>
    </div>)
}