import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Button, Header, Icon, Segment } from 'semantic-ui-react';

import CalendrierComponent from '../components/calendrier.component';

import { useAuth } from '../Auth.context';
import { UserRoles } from '../Enums';

import { jours } from '../FakeData'

export default function PageCalendrier (props){ 

    const navigate = useNavigate();
    const { user } = useAuth();

    if(user.accessLevel === UserRoles.DOCKER_COMMIS) navigate('/inspection');

    let creneaux = []

    for (let i = 6; i < 21; i++) {
        creneaux.push({
            heure: i,
            jsx: <Header size='small'>{i}H<br />{i+1}H</Header>,
        });
    }

    const [weekNumber, setWeekNumber] = useState();
    const [weekStart, setWeekStart] = useState();
    const [weekEnd, setWeekEnd] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);

        //TODO fetch days from backend

        let currentDate = new Date(Date.parse('26 Oct 2022'));
        let startDate = new Date(currentDate.getFullYear(), 0, 1);
        let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
            
        setWeekNumber(Math.ceil(days / 7));
        
        setWeekStart(new Date(jours[0].date).toLocaleDateString('Fr-fr', { weekday: 'long', day: 'numeric' }));
        setWeekEnd(new Date(jours[jours.length-1].date).toLocaleDateString('Fr-fr', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' }));
        
    })
    

    return (<>
        <Header 
        size='huge' 
        inverted>
            <Icon name='calendar alternate outline' />
            <Header.Content>
                Calendrier
                <Header.Subheader>
                    Liste des créneaux de la semaine
                </Header.Subheader>
            </Header.Content>
        </Header>

        <Segment.Group>
            <Segment 
            attached='bottom' 
            textAlign='center' 
            raised>

                <div className='calendrier-header'>
                    <div className='calendrier-header-side'>
                        <Button 
                        basic 
                        icon 
                        color='blue' 
                        labelPosition='left'>
                            <Icon name='left arrow' />
                            Semaine précédente
                        </Button>
                    </div>

                    <div className='calendrier-header-center'>
                        <Header size='huge'>
                            Semaine {weekNumber}
                            <Header.Subheader>
                                Du {weekStart} au {weekEnd} 
                            </Header.Subheader>
                        </Header>
                    </div>

                    <div className='calendrier-header-side'>
                        <Button 
                        basic 
                        icon 
                        color='blue' 
                        labelPosition='right'>
                            <Icon name='right arrow' />
                            Semaine suivante
                        </Button>
                    </div>
                </div>

            </Segment>
            <Segment attached='top'>

                <CalendrierComponent user={user} jours={jours} creneaux={creneaux} />
                
            </Segment>
        </Segment.Group>
    </>);
}