import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Table, Icon, Popup } from 'semantic-ui-react';

import PopupCreneauComponent from './popupCreneau.component';

import { useAuth } from '../Auth.context';
import { UserRoles } from '../Enums';

export default function CreneauComponent ({ jour, creneau }) {

    const { user } = useAuth();

    const [isHover, setHover] = useState(false);

    const navigate = useNavigate();

    let date = new Date(jour.date).setHours(creneau.heure);
    let old = date < new Date(Date.parse('26 Oct 2022 09:00'));//TODO change to local date
    let full = creneau.reserves === creneau.capacite;

    let bgColor, hoverBgColor, color = 'white', cursor = 'pointer';

    let creneauData, adminCreneauData;

    if(full){ //full
        bgColor = '#db2828';
        hoverBgColor = '#e56666';
    }else if(creneau.reserves > creneau.capacite/2){ // about to get full
        bgColor = '#fbbd08';
        hoverBgColor = '#fcd14f';
    }else{
        bgColor = '#21ba45';
        hoverBgColor = '#67e484';
    }

    if(old) {
        bgColor = '#333';
        hoverBgColor = '#595959';
        color = 'darkgrey'

        if(user.accessLevel <= UserRoles.TRANSPORTEUR_ADMIN) cursor = 'default'
    }

    if(creneau.capacite < 0){
        bgColor = '#e6e6e6';
        hoverBgColor = '#f2f2f2';
        color = '#333';

        if(user.accessLevel <= UserRoles.DOCKER_HELPDESK) cursor = 'default'
    }

    if(creneau.capacite >= 0){
        creneauData = <div className='creneau-data'>
                        <h1>Capacité : {creneau.capacite} </h1>
                        <h1>Restants : {creneau.capacite - creneau.reserves}</h1>
                    </div>
        adminCreneauData = <div className='creneau-data'>
                        <h1>Cap/Book : {creneau.capacite}/{creneau.reserves} </h1>
                        <h1>Effectués : {creneau.faits}/{creneau.reserves}</h1>
                    </div>
    }else{
        creneauData = <div className='creneau-data'>
                        <h1>Créneau fermé</h1>
                    </div>
        adminCreneauData = <div className='creneau-data'>
                        <h1>Créneau fermé</h1>
                    </div>
    }

    const cellStyle = { 
        backgroundColor: isHover ? hoverBgColor : bgColor, 
        color: color,
        border: '2px solid white',
        padding: 0, 
        cursor: cursor,
        position: 'relative'
    }

    const handleClick = (e) => {

        if(user.accessLevel <= UserRoles.TRANSPORTEUR_ADMIN){

            if(old) return
            if(creneau.capacite < 0) return
            navigate('/creneau', { state: { date, creneau } });

        }else if(user.accessLevel === UserRoles.DOCKER_HELPDESK){

            if(creneau.capacite < 0) return
            navigate('/interrogation', { state: { date, creneau }});

        }else if(user.accessLevel >= UserRoles.DOCKER_CHEF_PARC){

            navigate('/interrogation', { state: { date, creneau }});
        }
    }

    return (<>

    <Popup
    content={<PopupCreneauComponent creneau={creneau} />}
    hoverable
    disabled={user.accessLevel < UserRoles.DOCKER_CHEF_PARC}
    trigger={
        <Table.Cell
        disabled={(old && user.accessLevel <= UserRoles.TRANSPORTEUR_ADMIN)}
        style={cellStyle}>
            <div 
            className='creneau'
            onMouseEnter={(e) => { setHover(true) }}
            onMouseLeave={(e) => { setHover(false) }}
            onClick={handleClick}>

                <div className='creneau-heures' style={{ borderColor: creneau.capacite > 0 ? 'rgba(255, 255, 255, 0.75)' : 'rgba(51, 51, 51, 0.75)' }}>
                    <h1>
                        {creneau.heure < 10 ? '0'+creneau.heure : creneau.heure }H
                        <br />
                        {creneau.heure+1 < 10 ? '0'+(creneau.heure+1) : creneau.heure+1 }H
                    </h1>
                </div>

                {user.accessLevel >= UserRoles.DOCKER_HELPDESK ? 

                    adminCreneauData
                :
                    creneauData

                }

                {(user.accessLevel <= UserRoles.TRANSPORTEUR_ADMIN && creneau.pris > 0) && 
                <div className='creneau-pris'>
                    <Icon name='calendar alternate outline' /><h1>{creneau.pris}</h1>
                </div>}

                {(user.accessLevel >= UserRoles.DOCKER_CHEF_PARC && creneau.capacite < 0) && 
                <div className='creneau-pris' style={{borderColor: 'rgba(51, 51, 51, 0.75)' }}>
                    <Icon name='plus' />
                </div>
                }

            </div>
            
        </Table.Cell>
    } />
    </>)
}