import React, { useState, createContext } from 'react';

import { AuthContext } from './Auth.context';

export default function AuthProvider ({ children }) {

    const loggedOutUser = {
      connected: false,
      accessLevel: -1
    }

    const [user, setUser] = useState(loggedOutUser);
  
    const handleLogin = async (accessLevel) => { // TODO accesslevel is temporary
      setUser({
        connected: true,
        accessLevel: accessLevel // temporary
      })
    };
  
    const handleLogout = () => {
      setUser(loggedOutUser);
    };
  
    const value = {
      user,
      onLogin: handleLogin,
      onLogout: handleLogout,
    };
  
    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
};