import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Segment, Header, Icon, Label } from 'semantic-ui-react';

import FormPriseRdv from '../components/formPriseRdv.component';

import { formatFancyDate } from '../Utils';

export default function PageCreneau (props) {

    // initilisation de l'objet pour éviter des NPE sur la page
    let [date, setDate] = useState(Date.now());

    //TODO à connecter au backend
    let [rdvs, setRdvs] = useState([]);

    //TODO à enlever une fois le backend fait, aller chercher les données avec la date à la place
    let [creneau, setCreneau] = useState({
        heure: 0,
        capacite: 0, 
        fait: 0,
        reserves: 0,
        pris: 0,
    });

    let navigate = useNavigate();
    let location = useLocation();

    //vérification que les parametres sont présent après le chargement du composant, 
    //sinon la redirection ne fonctionne pas
    useEffect(() => {
        window.scrollTo(0, 0);

        if(!location.state){
            navigate('/', { replace: true });
            return
        }
        setDate(location.state.date);
        setCreneau(location.state.creneau);

        //TODO fetch rdvs from backend
    });

    let generateRibbon = () => {
        let color = 'green';
        let statut = 'Ouvert'

        if(creneau.capacite-creneau.reserves === 0){
            color = 'red'
            statut = 'Plein'
        }else if(creneau.reserves > creneau.capacite/2){
            color = 'orange'
            statut = 'Bientôt plein'
        }

        return (<Label color={color} tag>
            {creneau.capacite-creneau.reserves}
            <Label.Detail> rendez vous restants sur le créneau - {statut}</Label.Detail>
        </Label>)
    }

    return (<>
    <Header 
    size='huge' 
    inverted>
        <Icon name='clock outline' />
        <Header.Content>
            Créneau du {formatFancyDate(date)}
            <Header.Subheader>
                {generateRibbon()} 
            </Header.Subheader>
        </Header.Content>
    </Header>

    <Segment.Group>
        <Segment attached='bottom'>

            <FormPriseRdv sens='entree' full={(creneau.capacite-creneau.reserves<=0)} rdvs={rdvs} setRdvs={setRdvs} />
            
        </Segment>
        <Segment attached='top'>

            <FormPriseRdv sens='sortie' full={(creneau.capacite-creneau.reserves<=0)} rdvs={rdvs} setRdvs={setRdvs} />
            
        </Segment>
    </Segment.Group>
    </>)
}