import React from 'react';

import { Popup, Input } from 'semantic-ui-react'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export default function PopupDates ({ startDate, endDate, handleStartDateChange, handleEndDateChange }) {

    //TODO affiche de la date en français

    return (<>
    <Popup 
        flowing 
        on='click'
        position='bottom right'
        trigger={
            <Input
            type='text'
            readOnly
            value={startDate}
            style={{ maxWidth: '150px' }}
            />
        }>
        <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        startDate={startDate}
        showTimeSelect
        inline
        locale='fr'
        timeIntervals={60}
        dateFormat='dd/MM/yyyy'
        />
    </Popup>

    <Popup 
        flowing 
        on='click'
        position='bottom right'
        trigger={
            <Input
            type='text'
            readOnly
            value={endDate}
            style={{ 
                maxWidth: '150px',
                marginLeft: '15px' 
            }}
            />
        }>
        <DatePicker
        dateFormat='dd/MM/yyyy'
        selected={endDate}
        onChange={handleEndDateChange}
        startDate={endDate}
        showTimeSelect
        inline
        locale='fr'
        timeIntervals={60}
        />
    </Popup>
    
    </>)
}