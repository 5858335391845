import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ContainerValidator from 'container-validator';

import { fakeRdvEntree, fakeRdvSortie } from '../FakeData'

import { Table, Header, Icon, Form, Button, Message } from 'semantic-ui-react';

export default function FormPriseRdv (props) {

    // TODO ajouter numero de lot

    let navigate = useNavigate();

    let [noCtr, setNoCtr] = useState('');
    let [etat, setEtat] = useState();
    let [noRef, setNoRef] = useState('');
    let [iso, setIso] = useState('');
    let [navire, setNavire] = useState('');

    let [ctrChecked, setCtrChecked] = useState(false);
    let [refChecked, setRefChecked] = useState(false);

    let [loading, setLoading] = useState(false);

    let [ctrError, setCtrError] = useState(false);
    let [refError, setRefError] = useState(false);

    let resetFields = () => {
        setEtat('');
        setIso('');
        setNavire('');

        if(ctrChecked) setNoRef('')
    }

    // TODO handleType and check CTR nb
    let handleContainerType = (e, { value }) => {
        let validator = new ContainerValidator();

        value = value.toUpperCase()

        if(value.length > 11){
            e.preventDefault();
        }else if(value.length === 11){
            setNoCtr(value);

            if(validator.isValid(value)){
                //TODO backend check
                setCtrError(false);

                setLoading(true);

                setTimeout(() => {
                    setLoading(false);

                    if(props.sens === 'entree'){
                        setEtat(fakeRdvEntree.etat);
                        setNoRef(fakeRdvEntree.ref);
                        setIso(fakeRdvEntree.iso);
                        setNavire(fakeRdvEntree.navire);
                    }else{
                        setEtat(fakeRdvSortie.etat);
                        setNoRef(fakeRdvSortie.ref);
                        setIso(fakeRdvSortie.iso);
                        setNavire(fakeRdvSortie.navire);
                    }

                    setCtrChecked(true);
                }, 2000)
            }else{
                setCtrError(true);
            }
        }else{
            setNoCtr(value);

            resetFields();
            if(ctrError) setCtrError(false);
            if(ctrChecked) setCtrChecked(false);
        }
    }

    let handleReferenceType = (e, { value }) => {
        //TODO check reference
    }

    let handleButtonClick = (e) => {
        if(ctrChecked || refChecked){
            setLoading(true);

            //TODO replace fake timeout and connect to backend
            setTimeout(() => {
                setLoading(false);

                if(props.sens === 'entree'){
                    props.setRdvs(props.rdvs.concat([{
                        date: fakeRdvEntree.date,
                        no: fakeRdvEntree.no,
                        sens: fakeRdvEntree.sens,
                        ctr: fakeRdvEntree.ctr,
                        iso: fakeRdvEntree.iso,
                        etat: fakeRdvEntree.etat,
                        ref: fakeRdvEntree.ref,
                        navire: fakeRdvEntree.navire
                    }]))
                }else{
                    props.setRdvs(props.rdvs.concat([{
                        date: fakeRdvSortie.date,
                        no: fakeRdvSortie.no,
                        sens: fakeRdvSortie.sens,
                        ctr: fakeRdvSortie.ctr,
                        iso: fakeRdvSortie.iso,
                        etat: fakeRdvSortie.etat,
                        ref: fakeRdvSortie.ref,
                        navire: fakeRdvSortie.navire
                    }]))
                }
                
                resetFields();
                setNoCtr('')
                setCtrChecked(false);
                setRefChecked(false);
            }, 2000)
        }
    }

    return (<>
    <Header size='huge'>{props.sens === 'entree' ? 'Entrée(s)' : 'Sortie(s)'}</Header>

    <Table striped selectable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>

                    <Form loading={loading}>
                        <Form.Group>

                            <Form.Input 
                            label='Numéro de conteneur'
                            type='text'
                            action={!ctrChecked && { icon: 'search' }}
                            icon={ctrChecked && { name: 'check', color: 'green' }}
                            required='true'
                            error={ctrError }
                            disabled={props.full === true}
                            onChange={handleContainerType}
                            value={noCtr}
                            placeholder='No de conteneur' />

                            <Form.Input 
                            label='État'
                            type='text' 
                            placeholder='État'
                            width={2}
                            value={etat}
                            readOnly />

                            <Form.Input 
                            label='Numéro de référence'
                            type='text'
                            action={props.sens === 'sortie' && { icon: 'search' }}
                            placeholder='No de référence'
                            onChange={handleReferenceType}
                            value={noRef}
                            readOnly={props.sens === 'entree'}
                            disabled={props.full === true} />

                            <Form.Input 
                            label='Code ISO'
                            type='text'
                            placeholder='Code ISO'
                            width={2}
                            value={iso}
                            readOnly />

                            <Form.Input 
                            label='Navire'
                            type='text'
                            placeholder='Navire'
                            value={navire}
                            readOnly />

                            {props.full === false ? 
                                <Button
                                positive
                                size='big'
                                icon
                                disabled={!ctrChecked && !refChecked}
                                onClick={handleButtonClick}
                                labelPosition='left'>
                                    <Icon name='plus' />
                                    Prendre RDV
                                </Button>
                            :
                                <Button
                                color='yellow'
                                size='big'
                                icon
                                onClick={(e) => { navigate('/listes-d-attente') }}
                                labelPosition='left'>
                                    <Icon name='plus' />
                                    File d'attente
                                </Button> 
                            }

                        </Form.Group>
                    </Form>

                    {ctrError && 
                    <Message
                    error
                    icon='ban'
                    header='Erreur'
                    content='Veuillez saisir un numéro de conteneur valide' />}

                    {refError &&
                    <Message
                    error
                    icon='ban'
                    header='Erreur'
                    content='Veuillez saisir un numéro de référence valide' />}

                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>

        <Table.Body>
            {props.rdvs.map((rdv) => {
                if(rdv.sens === props.sens){
                    return <Table.Row>
                        <Table.Cell style={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center' 
                        }}>

                            <Header 
                            size='small'
                            style={{ margin: 0 }}>
                                <Icon name='calendar check outline' color='green' />
                                {rdv.ctr} - ({rdv.no}) - {rdv.iso} - {rdv.etat} - {rdv.ref} - {rdv.navire}
                            </Header>

                            <Button 
                            basic 
                            negative
                            icon='trash alternate outline'
                            labelPosition='left'
                            content='Supprimer le RDV' />

                        </Table.Cell>
                    </Table.Row>
                }else{
                    return (<></>)
                }
            })}
        </Table.Body>
    </Table>
    </>)
}